import React from "react"
import { navigate } from "gatsby"
import {
  login,
  isAuthenticated,
  getProfile,
  silentAuth,
  logout,
} from "../utils/auth"
import { Spin, Card, Button, Table, Dropdown, Menu } from "antd"
import styled from "styled-components"
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  PlusOutlined,
} from "@ant-design/icons"

const StyledLoginCard = styled(Card)`
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  margin: auto;
`

const StyledDashboardCard = styled(Card)`
  font-size: 20px;
`

const loginHeadStyle = {
  textAlign: "center",
  fontSize: "max(1.5em, 30px)",
}

const dashboardHeadStyle = {
  textAlign: "center",
  fontSize: "max(1.5em, 30px)",
}

// const SessionCheck = props => {
//   const [loading, setLoading] = React.useState(true)

//   const handleCheckSession = () => {
//     setLoading(false)
//   }

//   React.useEffect(() => {
//     silentAuth(handleCheckSession)
//   }, [])

//   return loading ? <Spin /> : <>{props.children}</>
// }

const Dashboard = ({ pageContext }) => {
  const isAuth = isAuthenticated()
  const [loading, setLoading] = React.useState(true)
  const [selectedKeys, setSelectedKeys] = React.useState([])
  const allProjects = pageContext.allProjects

  const handleCheckSession = () => {
    setLoading(false)
  }

  React.useEffect(() => {
    silentAuth(handleCheckSession)
  }, [])

  const onSelectChange = selectedRowKeys => {
    setSelectedKeys(selectedRowKeys)
  }

  const overlayMenu = () => (
    <Menu>
      <Menu.Item key="1" icon={<DeleteOutlined />}>
        DELETE
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
    },
    {
      key: "key",
      title: "Actions",
      dataIndex: "key",
      render: self => {
        console.log(self)
        return (
          <Dropdown.Button overlay={overlayMenu} icon={<EditOutlined />}>
            EDIT
          </Dropdown.Button>
        )
      },
    },
  ]

  const rowSelection = {
    selectedKeys,
    onChange: onSelectChange,
  }

  const tableData = allProjects.map(project => ({
    key: project.projID,
    name: project.projName,
    category: project.category,
  }))

  const NotLoggedOverview = () => {
    return (
      <StyledLoginCard
        title="Not Authenticated"
        actions={[
          <Button type="primary" onClick={() => login()}>
            Login
          </Button>,
          <Button danger onClick={() => navigate("/")}>
            Back
          </Button>,
        ]}
        headStyle={loginHeadStyle}
      >
        Please login to view this page.
      </StyledLoginCard>
    )
  }

  const user = getProfile()

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          {isAuth ? (
            <>
              <StyledDashboardCard
                title="Manage Projects"
                headStyle={dashboardHeadStyle}
                actions={[
                  <Button
                    danger
                    disabled={selectedKeys.length > 0 ? false : true}
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>,
                  <Button type="primary" icon={<PlusOutlined />}>
                    Create
                  </Button>,
                  <Button danger type="primary" onClick={() => logout()}>
                    Logout
                  </Button>,
                ]}
              >
                <Table
                  rowSelection={rowSelection}
                  dataSource={tableData}
                  columns={columns}
                />
              </StyledDashboardCard>
            </>
          ) : (
            <NotLoggedOverview />
          )}
        </>
      )}
    </>
  )
}

export default Dashboard
